<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [
        
      ]
    };
  },
  created() {
      this.title= this.$t('menuitems.app.my')
      this.items=[
        {
          text: this.$t('menuitems.app.text'),
          href: "/"
        },
        {
          text: this.$t('menuitems.app.my'),
          active: true
        }
      ]
      
    //   this.page.title=this.$t('menuitems.devices.lists')
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
  </Layout>
</template>